import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { catchError, filter, Observable, switchMap, tap } from 'rxjs';
import { User } from '../../generated/models/user';
import { AuthQuery } from '../routes/auth/auth.query';
import { AuthService } from '../routes/auth/auth.service';
import { ApiService } from '../shared/services/api.service';
import { AuthenticatedStore } from './authenticated.store';

@Injectable({ providedIn: 'root' })
export class AuthenticatedService {
  readonly authQuery = inject(AuthQuery);
  readonly authService = inject(AuthService);
  readonly authenticatedStore = inject(AuthenticatedStore);
  readonly api = inject(ApiService);
  readonly router = inject(Router);

  constructor() {
    this.authQuery.isSessionExpired$
      .pipe(
        takeUntilDestroyed(),
        filter((isExpired: boolean) => !!isExpired),
        switchMap(() => this.authService.refreshSession$()),
      )
      .subscribe();
  }

  updateUser(): Observable<User> {
    return this.authQuery.username$.pipe(
      switchMap((username) => this.api.getMyData({ cognitousername: username })),
      tap((user) => this.authenticatedStore.update({ user })),
      catchError((err) => {
        this.authenticatedStore.update({ user: undefined });
        throw err;
      }),
    );
  }

  signOut() {
    return this.authService.signOut().then(() => this.router.navigateByUrl('/auth'));
  }
}
